import React from "react";

/**
 * @typedef {object} Props
 * @prop {number|string} value
 * @prop {string} type
 * @prop {object} setValue
 * @prop {string} name
 * @prop {string=} placeholder
 * @prop {number=} min
 * @prop {boolean=} disabled
 * @prop {number=} max
 * @prop {number=} step
 * @prop {object=} style
 * input
 * @param {Props} Props
 * @returns
 */
const NewInput = ({
  value,
  type,
  setValue,
  name,
  placeholder,
  min,
  disabled = false,
  max,
  step = 0.5,
  style = {},
}) => {
  const handelValueChange = e => {
    e.preventDefault();
    const target = e.target,
      value =
        target.type === "number" ? parseFloat(target.value) : target.value;
    if (name === "test1") {
      setValue(state => ({
        ...state,
        [name]: value,
        test2: value,
      }));
    } else {
      setValue(state => ({
        ...state,
        [name]: value,
      }));
    }
  };

  return (
    <input
      type={type}
      placeholder={placeholder}
      name={name}
      value={value}
      min={min}
      max={max}
      disabled={disabled}
      step={step}
      onChange={handelValueChange}
      style={style}
    />
  );
};

const NewTextarea = ({
  value,
  name,
  setValue,
  disabled = false,
  placeholder,
  rows = 5,
  cols = 33,
}) => {
  const handelValueChange = e => {
    e.preventDefault();
    const value = e.target.value;
    setValue(state => ({ ...state, [name]: value }));
  };

  return (
    <textarea
      placeholder={placeholder}
      name={name}
      value={value}
      rows={rows}
      disabled={disabled}
      cols={cols}
      onChange={handelValueChange}
    />
  );
};

const NewSelect = ({ value, options, setValue, disabled = false, name }) => {
  const handelValueChange = e => {
    e.preventDefault();
    if (Array.isArray(options[0])) {
      const value = e.target.value,
        label = e.nativeEvent.target[e.target.selectedIndex].text;
      setValue(state => ({ ...state, [name]: [label, JSON.parse(value)] }));
    } else {
      const value = e.target.value;
      setValue(state => ({ ...state, [name]: JSON.parse(value) }));
    }
  };
  return (
    <select
      name={name}
      value={JSON.stringify(value)}
      onChange={handelValueChange}
      disabled={disabled}
    >
      {options.map((o, index) => {
        if (Array.isArray(o)) {
          const [label, optionValue] = o;
          return (
            <option key={index} value={JSON.stringify(optionValue)}>
              {label}
            </option>
          );
        }
        return (
          <option key={index} value={JSON.stringify(o)}>
            {o}
          </option>
        );
      })}
    </select>
  );
};

export { NewInput, NewSelect, NewTextarea };
