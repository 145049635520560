import React, { useContext } from "react";
import Modal from "react-modal";

import { InitModulesContext } from "../../../hooks/useContext";

// Modal.setAppElement("#___gatsby");
Modal.setAppElement("body");

const NewModal = ({
  setModalOpen,
  modalOpen,
  children,
  persist = false,
  beforeClosing = () => {},
}) => {
  const { breackpoints } = useContext(InitModulesContext);
  const modalCloseTimeout = 300;
  const modalStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.58)",
    },
    content: {
      position: "relative",
      top: "15%",
      left: "auto",
      right: "auto",
      bottom: "auto",
      transform: "none",
      maxWidth: "960px",
      margin: "2em auto",
      padding: "2em 1em 1em 1em",
      border: 0,
    },
  };
  const closeModal = e => {
    e.preventDefault();
    beforeClosing();
    if (!persist) {
      setModalOpen(false);
    }
  };

  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={closeModal}
      style={modalStyles}
      contentLabel="Modal"
      closeTimeoutMS={modalCloseTimeout}
    >
      {!persist && (
        <a
          href="#"
          onClick={closeModal}
          className="icon icon-window-close left-corner"
        >
          <span className="label">close</span>
        </a>
      )}
      {children}
    </Modal>
  );
};

export default NewModal;
