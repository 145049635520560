import React from "react";
import Img from "gatsby-image";

const Card = ({ item, Wrapper, loading }) => {
  return (
    <article className={`card card-${item.fluid.originalName}`}>
      <Wrapper>
        <div className={`card-${item.fluid.originalName} card--content`}>
          <h2 className="card--title">{item.title}</h2>
          <p className="card--description">{item.description}</p>
        </div>
        <div className={`card--imageContainer`}>
          <div className="card--image">
            {item.fluid && (
              <Img loading={loading} fluid={item.fluid} alt={item.title} />
            )}
          </div>
        </div>
      </Wrapper>
    </article>
  );
};

export default Card;
