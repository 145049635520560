import React, { lazy, Suspense } from "react";
const Latex = lazy(() => import("react-latex"));

import Loader from "components/loader/Loader";

const MyLatex = ({ value = null, children, display = true }) => {
  const text = value === null ? children : value;

  return (
    <Suspense fallback={<Loader />}>
      <Latex displayMode={false}>{`${text
        .replace(/\[/g, "{")
        .replace(/\]/g, "}")}`}</Latex>
    </Suspense>
  );
};

export default MyLatex;
