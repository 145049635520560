import React from "react";
import Mylatex from "../../MyLatex";

export const NewLabel = ({ name, value, style = {} }) => {
  return (
    <label style={style} htmlFor={name}>
      {value}
    </label>
  );
};

const LatexLabel = ({ toggleInputVisibility, value, name }) => {
  return (
    <label
      style={{
        position: "absolute",
        border: "none",
        fontSize: `${/\w+/.test(value) ? "0.7em" : "1.2em"}`,
        padding: `${/\w+/.test(value) ? "0" : "0.3em 1em"}`,
        fontWeight: "normal",
        backgroundColor: "white",
        display: "block",
        width: "100%",
        height: `${/\w+/.test(value) ? "auto" : "2.75em"}`,
        textAlign: `${value === "/" && "center"}`,
      }}
      htmlFor={name}
      onDoubleClick={toggleInputVisibility}
    >
      {/\w+/.test(value) ? (
        <Mylatex>{value}</Mylatex>
      ) : value === "/" ? (
        "بدون وحدة"
      ) : (
        value
      )}
    </label>
  );
};

export default LatexLabel;
